﻿

$font-primary: 'Roboto',Arial, sans-serif;

$white: #fff;
$black: #000000;


$primary: #8DC63F;
$secondary: #25aae1;



@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin transition($transition) {
    -moz-transition: all $transition ease;
    -o-transition: all $transition ease;
    -webkit-transition: all $transition ease;
    -ms-transition: all $transition ease;
    transition: all $transition ease;
}

@mixin gradient-background() {
    background: rgba(88,145,103,1);
    background: -moz-linear-gradient(45deg, rgba(88,145,103,1) 0%, rgba(88,145,103,0) 100%);
    background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(88,145,103,1)), color-stop(100%, rgba(88,145,103,0)));
    background: -webkit-linear-gradient(45deg, rgba(88,145,103,1) 0%, rgba(88,145,103,0) 100%);
    background: -o-linear-gradient(45deg, rgba(88,145,103,1) 0%, rgba(88,145,103,0) 100%);
    background: -ms-linear-gradient(45deg, rgba(88,145,103,1) 0%, rgba(88,145,103,0) 100%);
    background: linear-gradient(45deg, rgba(88,145,103,1) 0%, rgba(88,145,103,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#589167', endColorstr='#589167', GradientType=1 );
}

html {

}

body {
    width: 100vw;
    height: auto;
    font-family: $font-primary;
    background: $white;
    font-size: 17px;
    line-height: 1.8;
    font-weight: 400;

    &.menu-show {
        overflow: hidden;
        position: fixed;
        height: 100%;
        width: 100%;
    }
}
a {
    transition: .3s all ease;
    color: $primary;

    &:hover, &:focus {
        text-decoration: none;
        color: $primary;
        outline: none !important;
    }
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
    line-height: 1.5;
    color: rgba(0,0,0,.7);
    font-weight: 400;
}
