.slide {
    height: 75vh !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .slide-caption {
        background-color: rgba(31, 104, 141, 0.452);
        height: inherit;
        padding: 10vw;
        h1 {
            color: white;
            font-weight: bold;
            font-size: 5vh;
        }
        h2 {
            color: white;
            font-size: 3vh;
        }
    }
}