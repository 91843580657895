.HowItWorks-Card
{
    width: 375px;
    float:left;
    padding-right: 12.5px;
    padding-left: 12.5px;
    background-color: white;
    border-radius: 10px;
    width:600px;
    height: 500px;
    text-align: center;
    margin: 3vw;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);

}

.HowItWorks-Card-Holder
{
    padding-left:10vw;
    display: block;
    margin-right: auto;
    margin-left: auto;

}

.HowItWorks-page
{
    width: 100vw;
    background: #F7F7FF;
    height: 80vh;
    width:100%;
}