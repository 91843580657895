@import '../../style.scss';

* {
  box-sizing: border-box;
}

.transitionContainer {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  border-radius: 3px;
}

.container {
  margin: 1rem auto;
}

// @media (min-width: 769px) {
//   .container {
//     margin: 1rem auto;
//   }
// }
/* Result Component */
.card {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}

.result {
  padding: 1.5rem 2.5rem;
}
/* Quiz Component */
.answerOptions {
  margin: 0;
  padding: 1.5rem 2.5rem 0;
  list-style: none;
}

.answerOptions input {
  //padding: 1.5rem 2.5rem 0;
}
/* QuestionCount Component */
.questionCount {
  padding: 1.5rem 2.5rem 0;
  font-size: 14px;
}

.questionCount span {
  color: grey;
}
/* Question Component */
.question {
  margin: 0;
  padding: 0.5rem 2.5rem 1.5rem;
}
/* AnswerOption Component */
.borderSetting:hover {
  background-color: #eefbfe;
}

.radioCustomButton {
  top: 20px;
  left: 38px;
  width: 28px;
  height: 28px;
  padding: 0;
  border: 1px solid #bbb;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  transition: all 0.3s;
}

.radioCustomButton,
.radioCustomLabel {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.radioCustomLabel {
  position: relative;
  width: 100%;
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
}
/*.radioCustomButton :before {
    position: absolute;
    top: 20px;
    left: 38px;
    width: 28px;
    height: 28px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    background: #fff;
    border: 1px solid #bbb;
    border-radius: 50%;
    transition: all 0.3s;
}*/
.radioCustomButton:checked ~ .radioCustomLabel:before {
  content: '';
  background: #8bc53f;
  background-size: 27px;
  border-color: #8bc53f;
}
/* Animation */
.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out 0.3s;
}

.fade-leave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.button {
  //padding-left:2rem;
  padding: 0.5rem 2.5rem 1.5rem;
}
//details page
/*.formBasicPhoneNumber ,.formBasicFirstName {
    padding: 0.5rem 2.5rem 1.5rem 2.5rem;
}*/
.backButton {
  padding: 11px;
}
/*Result page CSS*/
.result-header h1 {
  color: $primary;
}

.advisor-details h3,
.result-nextSteps h2 {
  color: $secondary;
}

.result-nextSteps > h4 {
  text-align: left;
}

.course {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.5fr 1fr;
}

.course h6 {
  opacity: 0.6;
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: black;
}

.course h2 {
  letter-spacing: 1px;
  margin: 10px 0;
}

.course-preview-holly {
   // background-color: #2A265F;
    background-image: url("../../images/bio_picture.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    //color: #fff;
    padding: 30px;
    width: 300px;
}

.course-preview-yannek {
   // background-color: #2A265F;
    background-image: url("../../images/yannekBioPic.png");
    background-repeat: no-repeat;
    background-size: 100%;
    //color: #fff;
    padding: 30px;
    width: 300px;
}

.course-info {
  grid-column: 1;
  grid-row: 2;
  align-self: center;
}


/************************
*** START MEDIA QUERY ***
************************/
@media only screen and (min-width: 1001px) {
  .course {
    grid-template-columns: 0.5fr 1fr;
    grid-template-rows: 1fr;
  }

  .course-preview {
    grid-column: 1;
    grid-row: 1;
    max-width: 100%;
    margin: 5% 5%;
  }

  .course-info {
    grid-column: 2;
    grid-row: 1;
  }

  .result-creds {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    grid-template-rows: auto;
  }

  h6 {
    margin: 5% 5% 5% 0 !important;
  }

  .result-left-clm {
    grid-column: 1;
  }

  .result-right-clm {
    grid-column: 2;
  }
}
/**********************
*** END MEDIA QUERY ***
**********************/
.course-info {
  padding: 30px;
  position: relative;
  max-width: 100%;
}

.result-nextSteps h4 {
  font-size: 1rem;
}

.result-creds {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

h6 {
  margin: 5% 5% 5% 0 !important;
}
