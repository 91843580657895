

.Create_Account_Line
{
  background-color:#000000;
  width: 100%;
}

.Create_Account_Input_Box
{
  background-color:#e8e8e8 ;
  height:30px;
  text-indent:16px;
  font-size: 16pt;
  border-radius:1em;
  border: none;
  justify-content: center;
  width:100%;
}

.create_Account_Form_Inside
{
  padding-left: 20%;
  padding-right: 20%;
}

.Create_Account_Page
{
  background-color: #25aae1;
  height: 100vh;
  width: 100vw;
  padding-top: 10vh;
}


.Create_Account_Title
{
  padding-top: 1vh;
  padding-bottom: 1vh;
  color: black;
  font-style:unset;
  font-size: 26px;
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.create_Account_Form_Padding
{
  padding-bottom: 2%;
  padding-top: 2%;
}

.create_Account_Lone_Li
{
  list-style-type: none;
}

.Create_Account_Form
{
  background-color: white;
  justify-content: center;

  margin-left: auto;
  margin-right: auto;
  width: 675px;

  border-width:3px;
  border-style: solid;
  border-spacing: 50px;
  border-color:#8dc63f;
  border-radius: 2em;

  box-shadow: 0 2rem 2rem 0 rgba(0, 0, 0, 0.1);
}


.Create_Account_Logo
{
  display: block;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.create_Account_Part
{
  display:inline;
  width: 100%;
}

.Create_Account_List
{
  list-style: none;
}

.Create_Account_Input
{
  padding-top: 2%;
  padding-bottom: 2%;
  color:black;
}


.Create_Account_Disclaimer
{
  font-size: 15px;
  padding-left: 2%;
  padding-bottom: 2%;
  list-style-type: none;
}


.create_Account_TermsAndConditions
{
  display: block;
  margin-right: auto;
  &:hover {
    color: blue;
  }
  &:active {
    color: black;
  }
  opacity: 1 !important;
}

.Create_Account_Button_Box
{
  justify-content: center;
  
  display: block;
  padding-left: 65%;

  
}

.Create_Account_Button
{
  border-style: none;
  width: 100%;
  border-radius: 1em;
  background-color: #25aae1;
  &::before
  {
    color:#25aae1;
  }
  &:hover {
    background-color: #8dc63f;
  }
  &:active {
    color: #25aae1;
  }
}

.drop_Down_Select
{
  width: 120px;
}