@import '../../style.scss';


.log-in-box {
  display: flex;
  align-items: center;
  margin-top: 5;
  border-spacing: 50px;
  padding-top : 1.5em;
  padding-bottom : 1.5em;
  padding-right : 1.5em;
  padding-left : 1.5em;
  justify-content: center;
  margin-left:auto;
  margin-right:auto;
  top: 50%;
  width: 500px;
  text-align: center;
  border-radius: 10px;
  transition: all 0.2s;
  color:#000000;
  box-shadow: 0 2rem 2rem 0 rgba(0, 0, 0, 0.1);
  background-color: #e8e8e8;
  border-width:3px;
  border-style: solid;
  border-spacing: 50px;
  border-color:#8dc63f;
}

.log-in-box-padding
{
  height: 20%;
}

.elementPadding
{
  padding-top:40px;
  padding-bottom:40px;
}

.emailLine
{
  display:inline;
}



.listLineLeft
{
  padding-right: 70px;
}
.listLineRight
{
  font-size: 8pt;
  padding-left: 40px;
}

.screen-size
{
  height: 100vh;
  width: 100vw;
}

.login-form
{
  height:30px;
  text-indent:16px;
  font-size: 16pt;
  width: 100%;
  border-radius:1em;
  border: none;
  justify-content: center;
}

.googleLoginButton
{
  width: 80%;
}

.passwordLineLeft
{
  padding-right: 70px;
}

.passwordLineRight
{
  padding-left: 70px;
}


.googleLogIn
{
  width: 100vw;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.bodyStyle
{
  background: #F7F7FF;
  height: 87vh;
}

.log-in-box-list
{
  list-style-type: none;
}

.log-in-button
{
  border-style: none;
  border-radius: 1em;
  background-color: #25aae1;
  width: 30%;
  &::before
  {
    color:#25aae1;
  }
  &:hover {
    background-color: #8dc63f;
  }
  &:active {
    color: #25aae1;
  }
}


.forgotPassword
{
  color: #25aae1;
  &:hover {
    color: $primary;
  }
  &:active {
    color: $primary !important;
  }
  opacity: 1 !important;
}

.log-in-box-seperator
{
  background-color:#e8e8e8;

  width: 100%;
}

.nav-link {
  font-size: 20px;
  padding: 0.9rem 20px;
  color: $black;
  font-weight: 600;
  padding: 0;
  border: none;
  background: none;
  padding: 0.9rem 20px;
  color: $black;
  font-weight: 600;
  

  &:hover {
    color: $primary;
  }
  &:active {
    color: $primary !important;
  }
  opacity: 1 !important;
}