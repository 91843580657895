@import '../../style.scss';
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap');

.wrap {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 550px;
}

.logo {
  max-width: 90%;
  height: auto;
  display: block;
  background-color: transparent;
  background-image: url("../../images/logoNew.png");
  background-repeat:no-repeat;
}

@media only screen and (max-width:850px) {
  .logo {
    max-width: 75%;
  }
}

.logo-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ftco-navbar-light {
  background: transparent !important;
  z-index: 3;
  padding: 10px 0;
  @include media-breakpoint-down(md) {
    position: relative;
    top: 0;
    padding: 10px 15px;
  }

  .navbar-brand {
    color: $black;

    &:hover {
      color: $black;
    }
    @include media-breakpoint-down(md) {
      color: $white;
    }
  }

  .navbar-nav {
    @include media-breakpoint-down(md) {
      padding-bottom: 10px;
    }

    .nav-link {
      font-size: 15px;
      padding: 0.9rem 20px;
      color: $black;
      font-weight: 600;

      &:hover {
        color: $primary;
      }
      &:active {
        color: $primary !important;
      }
      opacity: 1 !important;
      @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        color: $white;
      }
    }

    > .nav-item {

      .dropdown-menu {
        border: none;
        background: $white;
        -webkit-box-shadow: 0 10px 34px -20px rgba(0,0,0,0.41);
        -moz-box-shadow: 0 10px 34px -20px rgba(0,0,0,0.41);
        box-shadow: 0 10px 34px -20px rgba(0,0,0,0.41);
      }

      &.ftco-seperator {
        position: relative;
        margin-left: 20px;
        padding-left: 20px;
        @include media-breakpoint-down(md) {
          padding-left: 0;
          margin-left: 0;
        }

        &:before {
          position: absolute;
          content: "";
          top: 10px;
          bottom: 10px;
          left: 0;
          width: 2px;
          background: rgba($white, .05);
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }

      &.cta {
        > a {
          color: $white;
          border: 1px solid $primary;
          padding: 0.5rem 18px;
          margin-top: 4px;
          background: $primary;
          @include border-radius(2px);

          span {
            display: inline-block;
            color: $white;
          }

          &:hover {
            background: $primary;
            border: 1px solid $primary;
          }
        }

        &.cta-colored {
          a {
            border: 1px solid $secondary;
            background: $secondary !important;
          }
        }
      }

      &.active {
        > a {
          color: $primary;
        }
      }
      @include media-breakpoint-down(md) {
        &.active {
          > a {
            color: $primary;
          }
        }
      }
    }
  }

  .navbar-toggle {
    border: none;
    color: rgba(255,255,255,.5) !important;
    cursor: pointer;
    padding: 0 2rem;
    // padding-right: 0;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0.1em;

    &:focus,
    &:hover {
      text-decoration: none;
      color: $primary;
      outline: none !important;
    }
  }

  &.scrolled {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    margin-top: -130px;
    background: $white !important;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.1);

    .nav-item {
      > .nav-link {
        @include media-breakpoint-down(md) {
          padding-left: 0 !important;
          padding-right: 0 !important;
          padding-bottom: 0 !important;
        }
      }

      &.active {
        > a {
          color: $primary !important;
        }
      }

      &.cta {
        > a {
          color: $white !important;
          background: $primary;
          border: none !important;
          padding: 0.5rem 20px !important;
          margin-top: 6px !important;
          @include border-radius(5px);

          span {
            display: inline-block;
            color: $white !important;
          }
        }

        &.cta-colored {
          span {
            border-color: $primary;
          }
        }
      }
    }

    .navbar-nav {
      @include media-breakpoint-down(md) {
        background: none;
        border-radius: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .navbar-toggle {
      border: none;
      color: rgba(0,0,0,.5) !important;
      border-color: rgba(0,0,0,.5) !important;
      cursor: pointer;
      padding-right: 0;
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: 0.1em;
    }

    .nav-link {
      padding-top: 0.9rem !important;
      padding-bottom: 0.9rem !important;
      color: $black !important;

      &.active {
        color: $primary !important;
      }
    }

    &.awake {
      margin-top: 0;
      transition: 0.3s all ease-out;
    }

    &.sleep {
      transition: 0.3s all ease-out;
    }

    .navbar-brand {
      color: $black;
    }
  }
}
//image settings to fit all screens

* {
  margin: 0;
  padding: 0;
}
// .containerBox {
//     width: 100vw;
//     height: 500px;
//   }

.containerBox {
  width: 100vw;
  height: auto;
}

.grid-box {
  width: 100vw;
  height: 100vh !important;
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.grid-header {
  display: none;
}

.grid-tagline {
  display: none;
}

.mobile-header {
  display: block;
  font-size: 2rem;
  padding: 2%;
  text-align: center;
  line-height: normal;
  font-family: "Arvo", sans-serif;
  color: $black;
}

.carousel1 {
  width: 100%;
  height: 100vh;
  display: block;
  background-image: url("../../images/illustrations/undraw_travelers_qlt1.svg");
  background-color: #F7F7FF;
  background-size: 38vh;
  background-repeat: no-repeat;
  background-position: 50% 10%;
}

.carousel2 {
  width: 100%;
  height: 100vh;
  display: block;
  background-image: url("../../images/illustrations/undraw_investing_7u74.svg");
  background-color: #F7F7FF;
  background-size: 45vh;
  background-repeat: no-repeat;
  background-position: 50% 20%;
}

.carousel3 {
  max-width: 100%;
  height: 100vh;
  display: block;
  background-image: url("../../images/illustrations/undraw_stock_prices_afrt.svg");
  background-color: #F7F7FF;
  background-repeat: no-repeat;
  background-size: 45vh;
  background-position: 50% 10%;
}

.text-box {
  grid-column: 1 / -1;
  grid-row: 2 / 4;
  align-self: center;
}

.grid-header {
  font-size: 2rem;
  line-height: normal;
  font-family: "Arvo", sans-serif;
  color: $black;
}

.grid-tagline {
  font-size: 1rem;
  color: $black;
}

.grid-btn {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.btn {
  grid-column: 1 / -1;
  justify-self: center;
}
/******************************
** START DESKTOP MEDIA QUERY **
******************************/
@media only screen and (min-width: 850px) {
  .mobile-header {
    display: none;
  }

  .grid-header {
    display: block;
  }

  .grid-tagline {
    display: block;
  }

  .grid-box {
    width: 100vw;
    height: 100vh;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    padding: 5%;
  }

  .carousel1 {
    width: 100%;
    height: 650px;
    background-size: 30%;
    background-position: 85% 40%;
  }

  .carousel2 {
    width: 100%;
    height: 650px;
    background-size: 40%;
    background-position: 10% 50%;
  }

  .carousel3 {
    width: 100%;
    height: 650px;
    background-size: 40%;
    background-position: 88% 40%;
  }

  .text1 {
    grid-column: 1 / 3;
    grid-row: 2;
    align-self: center;
    padding: 0 20%;
  }

  .text2 {
    grid-column: 2 / 4;
    grid-row: 2;
    align-self: center;
    margin-left: 15%;
    padding: 0 15%;
  }

  .text3 {
    grid-column: 1 / 3;
    grid-row: 2;
    justify-self: center;
    padding: 0 20%;
  }

  .grid-btn {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .btn {
    grid-column: 1 / -1;
    justify-self: left;
  }
}
/****************************
** END DESKTOP MEDIA QUERY **
****************************/
.carouselContainer {
  width: 100vw;
  height: 100vh;
}

.slider-text {
  height: 550px;
  position: relative;
}

.slider-text h2 {
  font-size: 2.2rem;
  color: #000000;
  padding-left: 10%;
  line-height: 1.1;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  text-align: left;
}

.slider-text h2 span {
  display: block;
  text-align: left;
}

.slider-text .img-video {
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #fff;
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  position: absolute;
}
@media (min-width: 768px) {
  .slider-text .img-video {
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
@media (max-width: 767.98px) {
  .slider-text .img-video {
    bottom: 80px;
    left: 0;
  }
}

.slider-text p {
  font-size: 0.95rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
  padding-left: 10%;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.slider-text .breadcrumbs {
  font-size: 14px;
  margin-bottom: 20px;
  z-index: 99;
  text-transform: uppercase;
  font-weight: 500;
}

.slider-text .breadcrumbs span {
  color: rgba(0, 0, 0, 0.7);
}

.slider-text .breadcrumbs span i {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
}

.slider-text .breadcrumbs span a {
  color: rgba(0, 0, 0, 0.7);
}

.slider-text .breadcrumbs span a:focus,
.slider-text .breadcrumbs span a:hover {
  color: #589167;
}

.slider-text .breadcrumbs span a:focus i,
.slider-text .breadcrumbs span a:hover i {
  color: #589167;
}

.slider-text .bread {
  font-weight: 500 !important;
  position: relative;
}

.hero-wrap {
  position: center;
}

.hero-wrap.hero-wrap-2 {
  height: 400px !important;
  position: relative;
}
// .hero-wrap.hero-wrap-2 .overlay {
//     width: 100%;
//     height: 400px;
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     content: '';
//     opacity: 1;
//     background: #589167;
//     background: -moz-linear-gradient(45deg, #589167 0%, rgba(88, 145, 103, 0) 100%);
//     background: -webkit-gradient(left bottom, right top, color-stop(0%, #589167), color-stop(100%, rgba(88, 145, 103, 0)));
//     background: -webkit-linear-gradient(45deg, #589167 0%, rgba(88, 145, 103, 0) 100%);
//     background: -o-linear-gradient(45deg, #589167 0%, rgba(88, 145, 103, 0) 100%);
//     background: -ms-linear-gradient(45deg, #589167 0%, rgba(88, 145, 103, 0) 100%);
//     background: linear-gradient(45deg, #589167 0%, rgba(88, 145, 103, 0) 100%);
//     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#589167', endColorstr='#589167', GradientType=1 );
// }
.hero-wrap.hero-wrap-2 .slider-text {
  height: 400px !important;
}
// .slider-text {
//     height: 550px;
//     position: relative;
//
//     h1 {
//         font-size: 60px;
//         color: $black;
//         line-height: 1.1;
//         font-weight: 500;
//
//         span {
//             display: block;
//         }
//     }
//
//
//
//     p {
//         font-size: 18px;
//         line-height: 1.5;
//         color: rgba(0,0,0,.8);
//     }
//
//     .breadcrumbs {
//         font-size: 14px;
//         margin-bottom: 20px;
//         z-index: 99;
//         text-transform: uppercase;
//         font-weight: 500;
//
//         span {
//             color: rgba(0,0,0,.7);
//
//             i {
//                 color: rgba(0,0,0,.5);
//                 font-size: 13px;
//             }
//
//             a {
//                 color: rgba(0,0,0,.7);
//
//                 &:hover, &:focus {
//                     color: $primary;
//
//                     i {
//                         color: $primary;
//                     }
//                 }
//             }
//         }
//     }
//
//     .bread {
//         font-weight: 500 !important;
//         position: relative;
//     }
// }
.ftco-animate {
  opacity: 0;
  visibility: hidden;
}
//INTRO
.ftco-intro {
  .intro {
    z-index: 0;
    position: relative;
    @include media-breakpoint-up(md) {
      margin-top: -70px;
    }

    &.aside-stretch {
      background: $primary;

      &:after {
        position: absolute;
        top: 0;
        right: 100%;
        bottom: 0;
        content: '';
        width: 360%;
        background: $primary;
        z-index: 99;
      }
      @include media-breakpoint-down(md) {
        &:after {
          background: transparent;
          display: none;
        }
      }
    }
  }
}

.button-align-left {
  float: left;
}
