@import '../../style.scss';

.how-it-works-page {
  background: #F7F7FF;
  height: 67vh;
}

.how-it-works-box {

  width: 100vw;
  height: auto;
  margin: 4% 5%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.works-box {
  text-align: center;
  margin: 5%;
  padding: 5%;
  border: none;
  border-radius: 0.5rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  background-color: white;
}

.matchmaking-box {
  grid-column: 1 / 4;
  grid-row: 1;
}

.customer-box {
  grid-column: 1 / 4;
  grid-row: 2;
}

.pricing-box {
  grid-column: 1 / 4;
  grid-row: 3;
}

.how-it-works-h3 {
  color: $secondary;
  margin: 0.5rem 0;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.how-it-works-h6 {
  color: $secondary;
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.how-it-works-p {
  text-align: left;
  margin: 0 5%;
}
@media only screen and (min-width: 850px) {
  .how-it-works-box {
    height: 85vh;
  }

  .matchmaking-box {
    grid-column: 1;
    grid-row: 1;
  }

  .customer-box {
    grid-column: 3;
    grid-row: 1;
  }

  .pricing-box {
    grid-column: 2;
    grid-row: 1;
  }
  // .how-it-works-h3 {
  //   font-size: 2vw;
  // }
  // .how-it-works-h6 {
  //   font-size: 1.3vw;
  // }
  .how-it-works-p {
    font-size: 1.2vw;
  }
}
