@import '../../style.scss';

.form-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  width: 100vw;
  height: 100vh;
  background: #F7F7FF;
}

.form-header {
  grid-column: 1;
  grid-row: 1;
  align-self: start;
  text-align: center;
  padding: 30px 0 5% 0 !important;
  color: $secondary;
  margin: 0.5rem 0;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.form {
  grid-column: 1;
  grid-row: 1 / 4;
  align-self: center;
  justify-self: center;
  padding-top: 5%;
}

.form-label {
  margin: 1rem 0 0 0 !important;
}

input {
  margin: 0 !important;
}

span {
  color: red;
}

textarea {
  margin: 0 !important;
}

#form-name {
  width: 80vw;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

#form-email {
  width: 80vw;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

#form-message {
  width: 80vw;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

#form-btn {
  width: 80vw;
  border: none;
  color: white;
  padding: 10px 32px;
  text-decoration: none;
  margin: 10% 0 !important;
  background-color: $secondary !important;

}


/******************************
** START DESKTOP MEDIA QUERY **
******************************/
@media only screen and (min-width: 850px) {
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    width: 100vw;
    height: 650px;
  }

  .form-header {
    grid-column: 2;
    grid-row: 1;
    align-self: start;
    text-align: center;
    padding-top: 50px;
    color: $secondary;
  }

  .form {
    grid-column: 1 / 4;
    grid-row: 1 / 4;
    align-self: center;
    justify-self: center;
  }

  .form-label {
    margin: 1rem 0 0 0 !important;
  }

  #form-name {
    width: 40vw;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
  }

  #form-email {
    width: 40vw;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
  }

  #form-message {
    width: 40vw;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
  }

  #form-btn {
    width: 40vw;
    border: none;
    color: white;
    padding: 16px 32px;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
    background-color: $primary;
  }
}
/****************************
** END DESKTOP MEDIA QUERY **
****************************/
