@import '../../style.scss';

section.pricing {
  background: #F7F7FF;
  //background: #98FB98;
  // background: linear-gradient(to right, $secondary, #93FEFF);
}

.pricing .card {
  border: none;
  border-radius: 0.5rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.pricing hr {
  margin: 1rem 0;
}

.pricing .card-title {
  color: $secondary;
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.pricing .card-price {
  color: $secondary;
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  //font-size: 0.8rem;
}

.pricing .fa-li {
  color: $secondary;
  margin: 0 0.3rem 0 0;
}

.pricing ul li {
  margin-bottom: 1rem;
  list-style: none;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%;
  border-radius: 0.5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}
/* Hover Effects on Card */
@media (min-width: 992px) {
  .pricing .card:hover .btn {
    opacity: 1;
  }
}
