.Pricing-Container
{
    margin-top:5vh;
    width: 1600px;
    display: block;
    margin-right: auto;
    margin-left: auto;

}

.Pricing-division-container
{
    width: 375px;
    float:left;
    padding-right: 12.5px;
    padding-left: 12.5px;

}

.Pricing-Page
{
    height: 67vh;
}

.pricing-disclaimer
{
    display: block;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    
}

.card-body
{
 
}